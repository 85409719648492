
.videos_emb{
    max-width: calc(100% - 60px);
}

.sub_title{
    font-size: 25px;
    margin-top: 50px;
}

.yb_slider{
    margin: 10px;
    max-width: 320px;
}

.yb_slider .ybs_img{
    border-radius: 15px;
    cursor: pointer;
}

.yb_slider .ybs_title{
    margin-top: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.yb_slider .yb_img_container{
    position: relative;
}

.yb_slider .yb_img_container .play_icon{
    position: absolute;
    pointer-events: none;
    border-radius: 15px;
    background-color: #0000006f;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.ybv_spinner {
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yb_slider:hover .play_icon{
    opacity: 1;
}

.yt_subscribe_btn {
    display: block;
    float: right;
    width: 140px;
    text-align: center;
    height: auto;
    padding: 8px;
    font-size: 15px !important;
    text-decoration: none;
    background-color: rgb(204, 0, 0);
    color: white !important;
    margin-top: 10px !important;
    border-radius: 4px;
}

.yb_info{
    white-space: pre-wrap;
}

.yb_show_more{
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width:750px){
    .bg{
        height: 650px;
    }

    
.mb-60 {
    margin-bottom: -7px;
}
}
@media only screen and (min-width:750px){
    .bg{
        height: 650px;
    }
    .mb-60 {
        margin-bottom: -7px;
    }
}




