@media only screen and (max-width:480px){
    .choose-area{
        margin-top: -80px;
    }
    #t2{
        font-size: 20px;
    }
}
@media only screen and (min-width:900px){
    .choose-area{
        margin-top:-80px
    }
}