.boxes {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.box {
    min-width: 80px;
    border-width: 1px;
    border-color: #0b1d33;
    border: 1px solid;
    text-align: left;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #0b1d33;
    line-height: 1.7em;
}

.calcps {
    background-color: aliceblue;
    color: #000000;
    border-radius: 20px;
    font-size: 18px;
    padding: 0 10px 0 10px;
}

@media only screen and (max-width:768px) {
    .boxes {
        flex-flow: column-reverse;
        display: block;
    }

    .box1 {
        min-width: 280px;
    }
}

@media only screen and (max-width:480px) {}

@media only screen and (min-width:768.1px) {}

@media screen and (min-width:769px) and (max-width:970px) {}