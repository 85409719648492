#coin {
    margin-top: -95px;
}

#Price {
    padding-top: -11px;
}

@media only screen and (max-width:480px) {
    #row2 {
        column-count: 2;

    }

    .partner-area {
        margin-top: -85px;
    }
}